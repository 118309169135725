import React from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './boca.scss';

import iconYoutube from '../images/icon-youtube.webp';
import iconFacebook from '../images/icon-facebook.webp';
import iconTwitter from '../images/icon-twitter.webp';
import iconInstagram from '../images/icon-instagram.webp';
import iconSnapchat from '../images/icon-snapchat.webp';

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />
        <div className={`${pageContext.slug} boca-wrapper`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.localFile.childImageSharp.fluid.src})`}}>
                        <h2 dangerouslySetInnerHTML={{ __html: pageContext.acf.bottom_quote}} />
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="first-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.first_paragraph}} />
                    </div>
                    <div className="col-12">
                        <div className="second-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.second_paragraph}} />
                        <div className="third-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.third_paragraph}} />
                    </div>
                </div>
            </div>
            {/* {pageContext.acf.dynamic_content_group.map((e,i) => {
                if(dynamicData[i].acf_fc_layout === 'add_full_width_video' ){
                    return (
                        <FullWidthVideo key={i} height={dynamicData[i].background_image.height/2} image={dynamicData[i].background_image.url}/>
                    )
                } else if(dynamicData[i].acf_fc_layout === 'add_single_image_with_text') {
                    return (
                        <SingleImageText key={i} imageHeight={dynamicData[i].image.height/2} imageWidth={dynamicData[i].image.width/2} image={dynamicData[i].image.url} text={dynamicData[i].text} alignment={dynamicData[i].alignment} />
                    )
                } else if(dynamicData[i].acf_fc_layout === 'add_single_video_with_text') {
                    return (
                        <SingleVideoText key={i} imageHeight={dynamicData[i].image.height/2} imageWidth={dynamicData[i].image.width/2} image={dynamicData[i].image.url} text={dynamicData[i].text} alignment={dynamicData[i].alignment} />
                    )
                } else if(dynamicData[i].acf_fc_layout === '3_image_container') {
                    return (
                        <ThreeImage key={i} imageOne={dynamicData[i].image_1.url} imageTwo={dynamicData[i].image_2.url} imageThree={dynamicData[i].image_3.url}/>
                    )
                } else if(dynamicData[i].acf_fc_layout === '1_image_container') {
                    return (
                        <OneImage key={i} width={dynamicData[i].image.width/2} height={dynamicData[i].image.height/2} imageOne={dynamicData[i].image.url}/>
                    )
                } else if(dynamicData[i].acf_fc_layout === '1_image_full') {
                    return (
                        <OneImageFull key={i} imageOne={dynamicData[i].image.url} height={dynamicData[i].image.height/2}/>
                    )
                } else if(dynamicData[i].acf_fc_layout === 'add_3_sections') {
                    return (
                        <ThreeSections key={i} sectionOne={dynamicData[i].section_1} sectionTwo={dynamicData[i].section_2} sectionThree={dynamicData[i].section_3} />
                    )
                } else {
                    return null;
                }
            })} */}
            <div className="container quote-wrapper">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className='btn-wrapper'>
                            <a href={pageContext.acf.website_url} target="_blank" rel="noopener noreferrer"><div className="btn btn-black">View Website</div></a>
                            <a href={pageContext.acf.learnMoreURL} className={pageContext.acf.learn_more_url ? '' : 'd-none'}><div className="btn btn-grey">Get in Touch</div></a>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="text-wrapper">
                            <h3>Boca Jets Lacrosse on Social Media</h3>
                        </div>
                        <div className="social-wrapper">
                            <a href="https://www.youtube.com/channel/UC4xbKrosGJW8O_A3T0dUmXw" target="_blank" rel="noopener noreferrer"><img src={iconYoutube} width="72" alt=""/></a>
                            <a href="https://www.facebook.com/Boca-Jets-Lacrosse-115987775128980/" target="_blank" rel="noopener noreferrer"><img src={iconFacebook} width="44" alt=""/></a>
                            <a href="https://twitter.com/bocajetslax?lang=en" target="_blank" rel="noopener noreferrer"><img src={iconTwitter} width="62" alt=""/></a>
                            <a href="https://www.instagram.com/bocajetslax/" target="_blank" rel="noopener noreferrer"><img src={iconInstagram} width="62" alt=""/></a>
                            <a href="http://snapchat.com/add/bocajetslax" target="_blank" rel="noopener noreferrer"><img src={iconSnapchat} width="63" alt=""/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)